import React from 'react'

const AccountPage = () => {
  return (
    <div style={{display:"grid",placeItems:"center",height:"100vh"}}> 
    <h2>This page will be available soon...</h2>
      
    </div>
  )
}

export default AccountPage
